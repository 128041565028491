import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

class CaseStudyRoll extends React.Component {
  getLastPosition(startPos, limit, totalCount) {
    let lastPos = 0;
    // display all case studies
    if (limit > totalCount) {
      lastPos = totalCount - 1;
    } else {
      // determine index or case study detail
      lastPos = startPos + limit - 1;
      // wrap around for case study detail
      if (lastPos > totalCount - 1) {
        lastPos = lastPos - totalCount;
      }
    }
    return lastPos;
  }
  render() {
    const { data } = this.props;
    const startPos = this.props.startPos;
    const limit = this.props.limit;
    const totalCount = data.allMarkdownRemark.totalCount;

    let projectList = {
      ...data.allMarkdownRemark,
    };

    let dataList = [];
    const node = projectList.edges;
    const relatedCaseStudy = this.props.relatedCaseStudy;

    if (relatedCaseStudy === null) {
      const lastPos = this.getLastPosition(startPos, limit, totalCount);
      // wrap around to handle case study detail
      if (startPos > lastPos) {
        for (let i = startPos; i <= totalCount - 1; ++i) {
          const rec = node[i];
          dataList.push({
            node: rec.node,
          });
        }
        for (let i = 0; i <= lastPos; ++i) {
          const rec = node[i];
          dataList.push({
            node: rec.node,
          });
        }
      }
      else {
        for (let i = startPos; i <= lastPos; ++i) {
          const rec = node[i];
          dataList.push({
            node: rec.node,
          });
        }
      }
    }
    else {
      // pages with related case studies
      for (let j = 0; j <= limit - 1; j++) {
        for (let i = startPos; i <= totalCount - 1; ++i) {
          const rec = node[i];
          if (
            rec.node.frontmatter.title === relatedCaseStudy.featuredtitle[j]
          ) {
            dataList.push({
              node: rec.node,
            });
          }
        }
      }
    }

    projectList = {
      edges: dataList,
    };

    const { edges: posts } = projectList;

    return (
      <ul className={"case-study-roll case-study-roll--" + limit}>
        {posts &&
          posts.map(({ node: post }) => (
            <li className={"case-study-item case-study-item--" + limit} key={post.id}>
              <Link className="focus:outline-none flex flex-col h-full" to={post.fields.slug}>
                {post.frontmatter.featuredimage ? (
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `Featured image thumbnail for post ${post.frontmatter.title}`,
                    }}
                  />
                ) : null}
                <div className={"case-study-title case-study-title--" + limit + " mb-6 md:m-0 mt-2 text-gray-600 md:truncate"}>
                  {post.frontmatter.shortname && (
                    <h2 className="font-medium inline text-indigo-800 pr-1">{post.frontmatter.shortname}:</h2>
                  )}
                  <p className="inline">
                    {post.frontmatter.title}
                  </p>
                </div>
              </Link>
            </li>
          ))}
      </ul>
    );
  }
}

CaseStudyRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query CaseStudyRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___featuredorder] }
          filter: { frontmatter: { templateKey: { eq: "case-study-post" } } }
        ) {
          totalCount
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                templateKey
                shortname
                title
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 1175, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <CaseStudyRoll data={data} {...props} />}
  />
);
