import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import CaseStudyRoll from "../components/CaseStudyRoll";
import Video from "../components/Video";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import ServiceList from '../components/ServiceList';

export const CaseStudyPostTemplate = ({
  content,
  contentComponent,
  description,
  collaborator,
  featuredorder,
  title,
  helmet,
  media,
  servicelinks,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <div className="hero bg-indigo-800">
        {helmet || ""}
        <div
          className="max-w-screen-xl pb-56 mx-auto pt-8 md:pt-20 px-6 text-center"
        >
          <div className="md:text-sm mb-2 text-blue-300 text-xs uppercase">
            {collaborator}
          </div>
          <h1
            className="font-serif leading-snug max-4-3xl md:leading-tight md:pb-16 md:text-5xl pb-6 text-2xl text-white"
          >
            {title}
          </h1>
        </div>
      </div>
      <div className="bg-gray-100 pb-56">
        <div className="max-w-screen-xl mx-auto pt-8 relative">
          <div className="-mt-56 mx-auto">
            {media.video ? (
              <Video videoSrcURL={media.video} videoTitle={title} />
            ) : (
              <div className="mx-6">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: media.image,
                    alt: `Media image ${title}`,
                  }}
                />
              </div>
            )}
          </div>
          <div className="lg:flex mt-10 md:mt-24">
            <div
              className="lg:justify-between lg:mr-6 lg:w-2/3 px-6 w-11/12"
            >
              <PostContent className="max-w-2xl text-content" content={content} />
            </div>
            {servicelinks && (
              <div className="mb-16 px-6 text-indigo-900">
                <h2 className="font-medium md:mb-8 md:text-lg mb-4">
                  Services
                </h2>
                <ul className="block font-serif leading-snug md:leading-tight md:text-2xl text-lg md:w-11/12">
                  <ServiceList nestedIn='case-study-post' relatedServices={servicelinks} />
                </ul>
              </div>
            )}
          </div>
          <div className="md:mt-24 md:pb-32 md:relative pb-20 px-4">
            <h2
              className="border-t border-gray-300 font-serif mb-6 md:mb-10 md:pt-20 md:text-5xl pt-10 text-2xl text-indigo-900"
            >
              Keep reading
            </h2>
            <CaseStudyRoll
              relatedCaseStudy={null}
              startPos={featuredorder}
              limit={2}
            />
            <Link
              className="border-2 border-blue-300 focus:outline-none focus:bg-blue-300 font-medium hover:bg-blue-300 inline-block md:absolute md:mr-3 md:right-0 md:mt-24 md:top-0 mt-4 py-4 px-6 rounded-full text-indigo-900"
              to="/case-studies"
              >View case studies
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

CaseStudyPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  collaborator: PropTypes.string,
  shortname: PropTypes.string,
  featuredorder: PropTypes.number,
  title: PropTypes.string,
  helmet: PropTypes.object,
  media: PropTypes.shape({
    video: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  servicelinks: PropTypes.array,
};

const CaseStudyPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <CaseStudyPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        collaborator={post.frontmatter.collaborator}
        shortname={post.frontmatter.shortname}
        featuredorder={post.frontmatter.featuredorder}
        helmet={
          <Helmet titleTemplate="%s Case Study | CVision AI">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        media={post.frontmatter.media}
        servicelinks={post.frontmatter.servicelinks}
      />
    </Layout>
  );
};

CaseStudyPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CaseStudyPost;

export const pageQuery = graphql`
  query CaseStudyPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        collaborator
        shortname
        title
        featuredorder
        media {
          video
          image {
            childImageSharp {
              fluid(maxWidth: 1175, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        servicelinks {
          title
        }
      }
    }
  }
`;