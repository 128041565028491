import React from "react";

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video mx-6 overflow-hidden relative" style={{paddingTop: "56.25%"}}>
    <iframe
      className="absolute h-full left-0 top-0 w-full"
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      height="670"
      width="1200"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
);
export default Video;
